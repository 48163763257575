import request from "@/utils/request"

// 获取用户信息
export function getUserInfor(params) {
    return request({
        url: "/user/info",
        method: "get",
        params,
    })
}

//资金动态
export function fundDynamics(params) {
    return request({
        url: "/user/funds",
        method: "get",
        params,
    })
}

export function assetsList(params) {
    return request({
        url: "/user/depositLoading",
        method: "get",
        params
    })
}

export function verifyUserInfor(data) {
    return request({
        url: "/user/confirm",
        method: "post",
        data
    })
}

