export default {
  message: {
    index: {
      heardTab: {
        "buyCoins": "Münzen kaufen",
        "transaction": "Transaktion",
        "download": "Herunterladen",
        "personalCenter": "Zentrum",
        "options": "Optionen",
        "contract": "Vertrag",
        "optionDetail": "Leichter Handel mit digitalen Währungen",
        "contractDetail": "Handel mit digitalen Währungsverträgen",
        "signIn": "Anmelden",
        "register": "Registrieren",
        "userDetail": "Benutzerdetails",
        "outLogin": "Abmelden"

      },
      bottomTab: {
        "clause": "Klausel und Richtlinien",
        "rate": "Tarif",
        "risk": "Risikohinweis",
        "moneyLaundering": "Geldwäsche",
        "server": "Nutzungsbedingungen",
        "Privacy": "Datenschutzerklärung",
        "support": "Unterstützung für eine Vielzahl von Währungen"
      },
      middleContent: {
        "line1": "Kaufen, handeln und halten Sie eine Vielzahl von Kryptowährungen auf OW",
        "line2": "Beginnen Sie mit wenigen einfachen Schritten Ihre erste Transaktion.",
        "line3": "Bleiben Sie mit unserer App jederzeit auf dem neuesten Stand.",
        "line4": "Wählen Sie den Handel mit bestimmten Bitcoin (BTC) Spot-Transaktionen und genießen Sie null Handelsgebühren.",
        "line5": "Über 1 Million Nutzer weltweit",
        "line6": "Aktuelle Markttrends",
        "line7": "Sie können zwischen Märkten und Verträgen wählen, um zu investieren.",
        "line8": "Vernünftige Investitionen",
        "line9": "Erhalten Sie umfassende Statistiken zu den Handlungen von Verkäufern und Käufern, um fundierte Entscheidungen zu treffen.",
        "registerLearn": "Registrieren",
        "line11": "Detaillierte Echtzeitstatistiken",
        "line12": "Sie können überall alle relevanten Informationen in Echtzeit einsehen und entscheiden, wie Sie handeln und investieren möchten.",
        "line13": "Erfahren Sie die neuesten Markttrends und verdienen Sie sofort Gewinne.",
        "join": "beitreten"
      },
    },
    signIn: {
      "signIn": "İmzala",
      "userName": "kullanıcı adı",
      "password": "parola",
      "forget": "Parola unutun?",
      "register": "kayıt",
    },
    forget: {
      "forget": "Passwort vergessen",
      "email": "E-Mail",
      "code": "Verifizierungscode",
      "inputPassword": "Neues Passwort eingeben",
      "confirmPassword": "Neues Passwort bestätigen",
      "confirm": "Bestätigen",
      "send": "Senden",
      "warning1": "Bitte geben Sie Ihre E-Mail-Adresse ein",
      "warning2": "Bitte geben Sie den Verifizierungscode ein",
      "warning3": "Bitte geben Sie ein Passwort ein",
      "warning4": "Bitte bestätigen Sie das Passwort",
      "warning5": "Die eingegebenen Passwörter stimmen nicht überein. Bitte geben Sie sie erneut ein."
    },
    register: {
      "title": "Registrierung",
      "username": "Benutzername",
      "VerificationCode": "Verifizierungscode",
      "password": "Passwort",
      "InvitationCode": "Einladungscode"

    },
    download: {
      "line1": "Schnelles Trading, sichere Aufbewahrung, nächste Generation der Kryptowährungshandelsprodukte",
      "line2": "Ermöglicht Ihnen bequemes Trading von überall und jederzeit",
      "line3": "Laden Sie die sichere und effiziente Währungshandel-App herunter"
    },
    contract: {
      "winorlose":"Gewinn/verlust",
      "orderList": "Auftragsliste",
      "minute": "Minute",
      "hour": "Stunde",
      "Time-sharing": "Zeitablauf",
      "contract": "Kontrakt-Handel",
      "all": "Vollständig",
      "open": "Eröffnen",
      "close": "Schließen",
      "limit_order": "Limit-Auftrag",
      "market_order": "Markt-Auftrag",
      "price": "Preis",
      "inputPrice": "Geben Sie den Preis ein",
      "amount": "Menge",
      "inputAmount": "Geben Sie die Menge ein",
      "money": "Geld",
      "inputMoney": "Geben Sie den Betrag ein",
      "available": "Verfügbar",
      "avail": "Verfügbar zum Schließen",
      "buy_up": "Kauf",
      "sell_out": "Verkauf",
      "cost": "Sicherheitsleistung",
      "max_short": "Maximaler Short",
      "max_long": "Maximaler Long",
      "open_orders": "Offene Aufträge",
      "positions": "Positionen",
      "history": "Historie der Aufträge",
      "symbol": "Symbol",
      "lever": "Hebel",
      "direction": "Richtung",
      "hold_shares_volume": "Bestand",
      "open_average_price": "Durchschnittlicher Eröffnungspreis",
      "operation": "Operation",
      "noData": "Keine Daten",
      "margin_ratio": "Sicherheitsleistungssatz",
      "market_price": "Marktpreis",
      "estliqprice": "Voraussichtlicher Liquidationspreis",
      "openPrice": "Durchschnittlicher Eröffnungspreis",
      "closePrice": "Durchschnittlicher Schlusspreis",
      "profit": "Gewinn",
      "Yield": "Rendite",
      "fee": "Gebühr",
      "total_amount": "Gesamtmenge",
      "time": "Zeit",
      "status": "Status",
      "close_fast": "Sofortiges Schließen zum Marktpreis",
      "close_limit": "Limit-Auftrag zum Schließen",
      "close_market": "Markt-Auftrag zum Schließen",
      "availAmount": "Verfügbare Menge zum Schließen",
      "warning1": "Bitte geben Sie zuerst den Preis ein",
      "cancel": "Abbrechen",
      "stop": "Stop-Loss / Take-Profit",
      "stopAmount": "Verfügbare Menge zum Ausführen",
      "startPrice": "Auslösepreis",
      "takeProfit": "Take-Profit",
      "stopLoss": "Stop-Loss",
      "message1": "Bitte wählen Sie entweder Stop-Loss oder Take-Profit",
      "message2": "Bitte wählen Sie den Auslösepreis für Stop-Loss oder Take-Profit",
      "message3": "Bitte wählen Sie die Menge",
      "message4": "Der Take-Profit-Preis darf nicht unter dem Eröffnungspreis liegen",
      "message5": "Der Stop-Loss-Preis darf nicht über dem Eröffnungspreis liegen",
      "message6": "Der Gewinnziel-Preis darf nicht höher sein als der Eröffnungspreis.",
      "message7": "Der Stop-Loss-Preis darf nicht niedriger sein als der Eröffnungspreis."
    },
    options: {
      "current_trading": "Aktuelle Position",
      "order_history": "Bestellverlauf",
      "open_time": "Eröffnungszeit",
      "close_time": "Schließungszeit",
      "count_down": "Countdown",
      "Buy_up": "Kauf Hoch",
      "Buy_down": "Kauf Tief",
      "options": "Binärhandel",
      "estimatedProfit": "Geschätzter Gewinn",
      "history": "Letzte Kursänderungen",
      "rise": "Steigen",
      "fall": "Fallen",
      "upAndDown": "Steigen und Fallen",
      "dan": "Einzel",
      "warning1": "Ihr Guthaben ist nicht ausreichend. Bitte geben Sie die Handelsmenge erneut ein.",
      "warning2": "Bitte geben Sie eine korrekte Handelsmenge ein."
    },
    translateNav: {
      "line1": "Währung suchen",
      "line2": "Alle"
    },
    userInfo: {
      "title": "Persönliche Übersicht",
      "nickname": "Spitzname",
      "assets": "Vermögensverteilung",
      "allAssets": "Gesamtvermögen",
      "Todayearnings": "Heutige Gewinne",
      "account": "Konto",
      "CapitalAccount": "Kapitalkonto",
      "optionsAccount": "Optionskonto",
      "contractAccount": "Vertragskonto",
      "AssetEstimation": "Vermögensschätzung",
      "Recharge": "Aufladen",
      "CashWithdrawal": "Abheben",
      "CapitalFlow": "Kapitalfluss",
      "date": "Datum",
      "quantity": "Menge",
      "operation": "Operation",
      "Transfer": "Übertragen"

    },
    Recharge: {
      "buy": "Kaufen",
      "WalletAddress": "Wallet-Adresse",
      "tip": "Aufladetyp"

    },
    withdrawal: {
      "withdrawal": "Auszahlung",
      "WalletAddress": "Wallet-Adresse",
      "inputWalletAddress": "Geben Sie die Wallet-Adresse ein",
      "AvailableBalance": "Verfügbares Guthaben",
      "all": "Alles abheben",
      "wantWithdrawal": "Ich möchte abheben",
      "selectNetWork": "Netzwerk auswählen",
      "withdrawalUSDT": "USDT abheben"
    },
    Transfer: {
      "title": "Kapitalübertragung",
      "from": "Von",
      "to": "Nach",
      "capitalAccount": "Kapitalkonto",
      "optionsAccount": "Optionskonto",
      "contractAccount": "Vertragskonto",
      "amount": "Menge",
      "all": "Alles übertragen",
      "cancel": "Abbrechen",
      "confirm": "Bestätigen"
    },
    assets: {
      "title": "Vermögensverteilung",
      "allAssets": "Gesamtvermögen",
      "account": "Konto",
      "Recharge": "Aufladen",
      "withdrawal": "Auszahlung",
      "Transfer": "Übertragung"
    },
    assetEstimation: {
      "title": "Vermögensschätzung"
    },
    capitalFlow: {
      "title": "Kapitalfluss",
      "date": "Datum",
      "amount": "Menge",
      "operation": "Operation",
      "Withdrawing": "In Auszahlung"
    },
    verifyUser: {
      "Authenti": "Identitätsprüfung",
      "username": "Name",
      "idCard": "Personalausweisnummer",
      "fond": "Vorderseite",
      "reverse": "Rückseite",
      "submit": "Einreichen",
      "inputId": "Bitte geben Sie zuerst Ihre Personalausweisnummer ein",
      "inputUsername": "Bitte geben Sie Ihren Namen ein",
      "type": "Das hochgeladene Profilbild muss im JPG- oder PNG-Format sein!",
      "size": "Die Größe des hochgeladenen Profilbilds darf 2 MB nicht überschreiten!",
      "inputfond": "Bitte laden Sie ein Foto der Vorderseite des Personalausweises hoch",
      "inputreverse": "Bitte laden Sie ein Foto der Rückseite des Personalausweises hoch"
    }
  },
}
