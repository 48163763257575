import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import message from "@icon-park/vue/lib/icons/Message";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "",
        name: "index",
        component: () => import("../views/index/index.vue"),
      },
      {
        path: "/verifyUser",
        name: "verifyUser",
        component: () => import("../views/verifyUser/index.vue"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("../views/login/index.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () => import("../views/register/index.vue"),
      },
      {
        path: "/forgetPassword",
        name: "forgetPassword",
        component: () => import("../views/forgetPassword/index.vue"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/order/index.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/contract",
        name: "contract",
        component: () => import("../views/contract/index.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/binary",
        name: "binary",
        component: () => import("../views/binary/index.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/userAssets",
        name: "userAssets",
        component: () => import("../views/userAssets/index.vue"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/download",
        name: "download",
        component: () => import("../views/download/index.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "notfound",
    component: () => import("../views/404/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// 路由守卫
router.beforeEach((to, from, next) => {
  //如果要去My或者index页面
  if (to.meta.requireAuth) {
    //判断l如果ocalStorage里面是否有Username
    if (localStorage.getItem("access_token")) {
      //有用户信息让它继续走
      next(true);
    } else {
      Message.warning("Not logged in");
      next({ path: "/login", query: { topath: to.path } });
      //否则让它跳转到登录界面，跳转到登录界面时，需要把to.path传入，方便在登录成功时跳转到对应界面上
    }
  } else {
    //否则去其他界面时 让它继续走
    next(true);
  }
});

export default router;
