import Vue from "vue"
import VueI18n from "vue-i18n"
import enLocale from "./lang/language-EN"
import cnLocale from "./lang/language-CN"
import jpLocale from "./lang/language-JP"
import tkyLocale from "./lang/language-TKY"
import krLocale from "./lang/language-KR"
import Spin from "./lang/language-SPIN"
import Ge from "./lang/language-GE"
import French from "./lang/language-FC"

Vue.use(VueI18n)
const messages = {
    en: {
        ...enLocale,
    },
    zh_CN: {
        ...cnLocale,
    },
    jp: {
        ...jpLocale,
    },
    tky: {
        ...tkyLocale,
    },
    kr: {
        ...krLocale,
    },
    spin: {
        ...Spin,
    },
    ge: {
        ...Ge
    },
    fc: {
        ...French
    }
}
const i18n = new VueI18n({
    locale: localStorage.getItem("ucoinexLang") || "en",
    messages,
})

export default i18n
